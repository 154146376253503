<template>
  <div class="align-wrapper">
    <div class="demand-card-group" :style="customStyle">
      <div v-for="(d, index) in data" :key="index" :class="{'profile-card': large}">
        <ProfileCardNew @updateSublist="updateSublist" :blacklist="blacklist" :profile="d" :size="size" v-if="typeof d !== 'number'" :index="index" :large="large" :noBottom="noBottom" :isSearch="isSearch" :isSettled="isSettled">

        </ProfileCardNew>
      </div>
      <div v-if="isLastPage" class="last-page-more">
        <button @click="getParticipleLists" class="more-button">点击查看更多内容</button>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileCardNew from "@/components/profile/ProfileCardNew";


export default {
  name: "ProfileCardGroupNew",
  components: {ProfileCardNew},
  emits:["getParticipleLists"],
  props: {
    isSettled:{
      // 是否入驻页面
      type: Boolean,
      default: () => false
    },
    isSearch:{
      // 是否搜索页面
      type: Boolean,
      default: () => false
    },
    isLastPage:{  // 是否最后一页
      type: Boolean,
      default: () => false
    },
    noBottom:{
      type: Boolean,
      default: () => false
    },
    blacklist: {
      type: Boolean,
      default: () => false
    },
    data: {
      type: Array,
      default: () => {
        return [{}, {}, {}, {}, {}]
      },
      required: true
    },
    large: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: String,
      default: () => "large"
    },
    lineItems: {
      type: Number,
      default: () => 3
    },
    customStyle: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    /* filledData() {
      // 填充空白数据
      let amountToFill = (this.lineItems - this.data.length % this.lineItems) % this.lineItems
      return this.data.concat([...new Array(amountToFill).keys()])
    } */
  },
  methods: {
    getParticipleLists(){ // 获取更多精细的专家
      this.$emit("getParticipleLists")
    },
    updateSublist() {
      this.$emit('updateSublist')
    }
  }
}
</script>

<style scoped lang="scss">
.more-button{
  cursor: pointer;
  width: 180px;
  height: 48px;
  border: none;
  border-radius: 24px;
  background: $--color-theme;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: 48px;
}
.last-page-more{
  padding: 30px 0;
  // border: 1px solid #D8D8D8;
  box-sizing: border-box;
  width: 100%;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.profile-card{
  width: 100%;
}
.demand-card-group {
  width: 100%;
}
.demand-card-placeholder {
  width: 376px;
}
.small-placeholder {
  width: 220px;
}
</style>