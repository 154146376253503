<template>
<div>
  <div class="edit-container">
    <el-card style="width: 900px" v-loading="loading" shadow="hover">
      <template #header>
        <span class="card-top">个人信息修改</span>
      </template>
      <div class="card-content" v-if="!loading">
        <el-form ref="form" :model="profile" label-width="150px" :class="{'el-form-content': userIdentity===1}">
          <el-form-item label="用户名">
            <el-input v-model="profile.name" class="width360" maxlength="10" show-word-limit/>
          </el-form-item>
          <el-form-item label="所属实验室" v-if="userIdentity==0">
            <el-input disabled v-model="profile.organizationName" class="width360" />
          </el-form-item>
          <el-form-item label="所属单位" v-if="userIdentity==0">
            <el-input disabled v-model="profile.belong" class="width360" />
          </el-form-item>
          <el-form-item label="学历" v-if="userIdentity==0">
            <el-select placeholder="-" v-model="profile.educationId" class="width360">
              <el-option v-for="o in educationOptions" :key="o.id" :value="o.id" :label="o.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职称" v-if="userIdentity==0">
            <el-select placeholder="-" v-model="profile.positionId" class="width360">
              <el-option v-for="o in positionOptions" :key="o.id" :value="o.id" :label="o.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="科研方向" v-if="userIdentity==0">
            <el-select
                class="width360"
                v-model="profile.label"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="输入单个方向后按enter键确认">
            </el-select>
          </el-form-item>
          <!-- 1.7改为多选 -->
          <el-form-item label="技术可应用行业" class="width360" v-if="userIdentity==0">
            <!-- 行业多选 -->
            <MultiSelection :selections="multiIndustry" :placeholder="'请选择技术可应用行业'" :max="10" :ref="'industrys'" :value="userIndustryIds.join(',')" style="width: 340px;"></MultiSelection>
          </el-form-item>
          <el-form-item label="邮箱" v-if="userIdentity==0">
            <el-input v-model="profile.email" class="width360" />
          </el-form-item>
          <el-form-item label="部门" v-if="userIdentity==1 && showNewItems">
            <el-select placeholder="-" v-model="departmentId" class="width360">
              <el-option v-for="o in departmentSelections" :key="o.id" :value="o.id" :label="o.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职位" v-if="userIdentity==1 && showNewItems">
            <el-select placeholder="-" v-model="positionId" class="width360">
              <el-option v-for="o in positionSelections" :key="o.id" :value="o.id" :label="o.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="行业" class="width360" v-if="userIdentity == 1">
            <!-- 行业多选 -->
            <MultiSelection :selections="multiIndustry" :placeholder="'请选择行业'" :max="10" :ref="'industrys'" :value="userIndustryIds.join(',')" style="width: 340px;"></MultiSelection>
          </el-form-item>
          <!-- 1.10.14 数字科创研究方向 -->
          <el-form-item label="研究方向" v-if="userIdentity==1">
            <el-input v-model="userDirection" class="width360" placeholder="最多输入3个，以“,”分割，最长30个字符" maxlength="30"/>
          </el-form-item>
          <!-- 1.8.4 新增自我介绍 -->
          <el-form-item label="自我介绍">
            <el-input v-model="profile.selfIntroduction" class="width360" type="textarea" :minlength="10" :maxlength="400" show-word-limit placeholder="请输入自我介绍"  resize="none" rows="9"/>
          </el-form-item>
          <el-form-item>
            <div style="margin-top:90px;"></div>
            <el-button :loading="btnDisabled" type="primary" @click="saveProfile()">保存</el-button>
          </el-form-item>
        </el-form>
        <div class="avatar-fix">
          <el-upload
            action="#"
            :accept="'image/*'"
            :show-file-list="false"
            :http-request="handleUpload"
            :on-change="loadJsonFromFile">
            <img v-if="imgdisplay" :src="staticPath + imgdisplay" class="avator-center" />
            <i v-else class="el-icon-plus"></i>
            <div class="avatar-wrapper">
              <el-button :loading="btnDisabled" type="primary">修改头像</el-button>
              <el-progress style="width: 200px;margin-top: 10px;" :text-inside="true" v-if="percentage !== 100" :percentage="percentage"></el-progress>
            </div>
          </el-upload>
        </div>
      </div>
      <div v-else style="height: 549px;"></div>
    </el-card>
  </div>
</div>
</template>

<script>
import { updateProfile } from '@/config/WebTIMConfig'    // 对腾讯个人信息进行更新
import {mapGetters} from 'vuex'
import {editProfile,editProfileBasic,memberMessageDetail} from "@/api/user";
import {ElMessage} from "element-plus";
import {educationType, getOptionsByType, industryType, positionType, userServeType} from "@/api/config";
import {staticPath, bucketMap} from "@/utils/consts";
import {randomFilename} from "@/utils/time";
import { uploadTypeImage, uploadTypeVideo, cosByUploadType} from "@/api/upload";
import { getSelectFatherSonByType } from "@/api/config";
import MultiSelection from '@/components/MultiSelection/MultiSelection';

export default {
  name: "ProfileEdit",
  computed: {
    showNewItems(){
      // 是否展示部门、职位选项
      return this.enterpriseType?.indexOf('个人') === -1 && this.profile?.organizationType + '' === '1'
    },
    ...mapGetters(['id','enterpriseType'])
  },
  components:{
    MultiSelection
  },
  data() {
    return {
      positionId: '', // 职位Id
      positionSelections: [], // 职位选项
      departmentId: '', // 部门Id
      departmentSelections: [], // 部门选项
      directionHoler: '',
      userDirection: '',  // 研究方向
			userIdentity: 0,
      loading: true,
      profile: {},
      positionOptions: [],
      educationOptions: [],
      multiIndustry: [],  // 行业多选
      userIndustryIds: [],  // 用户提交的行业
      industryOptions: [],
      techserviceOptions: [],
      imageUrl: '',
      imgdisplay: '',
      staticPath: '',
      btnDisabled: false,
      percentage: 100,
      ElMessage
    }
  },
  created() {
    this.$store.commit('message/SET_ACTIVENAV', '-')
    this.$store.commit('message/SET_BG', false)
  },
  async mounted() {
		this.staticPath = staticPath
    await this.getPositionOptions()
    await this.getEducationOptions()
    await this.getIndustryOptions()
    await this.gettechserviceOptions()
    await this.initMyInfo()
  },
  methods: {
    async handleUpload (val) {
      var result = await this.directUpload(val.file, uploadTypeImage, true)
      this.imgdisplay  = result.Location.slice(result.Location.indexOf("/"))
      this.btnDisabled = false
    },
    directUpload (file, uploadType) {
      var self = this
      let cosMap = {
        image: cosByUploadType(uploadTypeImage),
        video: cosByUploadType(uploadTypeVideo)
      }
      let cos = uploadType === uploadTypeImage ? cosMap.image : cosMap.video
      let bucket = uploadType === uploadTypeImage ? bucketMap.image : bucketMap.video
      return new Promise((resolve, reject) => {
      cos.putObject(
        {
          Bucket: bucket,
          Region: "ap-shanghai",
          Key: randomFilename(),
          StorageClass: 'STANDARD',
          Body: file,
          onProgress: function (progressData) {
            console.log(progressData)
            self.btnDisabled = true
            self.percentage = parseInt(progressData.percent * 100)
          }
        },
        function (err, data) {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        }
      );
      })
    },
    labelRule() {        // userIdentity === 0 表示实验室
      if((!this.profile.email || this.profile.email.length === 0) && this.userIdentity === 0 ){
        this.ElMessage.warning("请填入邮箱")
        return false
      }
      let reg = /^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if (!reg.test(this.profile.email) && this.userIdentity === 0) {
        this.ElMessage.warning("邮箱格式不正确")
        return false
      }
      return true
    },
    checkDirection(temp){ // 判断研究方向
      const num = 3 // 个数
      const perLength = 10  // 每个长度
      if(!temp){  // 空判断
        ElMessage.warning('请输入研究方向!')
        return
      }
      if(temp.split(',').length > num){ // 研究方向个数
        ElMessage.warning('研究方向最多输入' + num + '个!')
        return false
      }
      let flag = false
      temp.split(',').forEach((item)=>{
        if(item.length > perLength){
          flag = true
          return true
        }
      })
      if(flag){
        ElMessage.warning('研究方向每个不超过' + perLength + '个字!')
        return false
      }
      return true
    },
    async getNewSelections(){
      // 获取新选项
      let res = await getSelectFatherSonByType(90)
      this.positionSelections = res.data
      res = await getSelectFatherSonByType(91)
      this.departmentSelections = res.data
    },
    async initMyInfo() {
      this.profile.id = this.id
      if(!this.id) return // 空判断
      let res = await memberMessageDetail(this.id)
      this.userIndustryIds = res.data.industryIds
      this.profile = res.data
      this.profile.headImage = res.data.headImage || "../../assets/images/avatar.jpg"
      this.imgdisplay = this.profile.headImage
			this.profile.industryId = this.profile.industryIds[0]   // 改为多选后，选择初始项赋值
      this.profile.label = res.data.label || []
      if(this.profile.label.length > 0){  // 数字科创研究方向获取
        this.userDirection = res.data.label.join(',')
      }
      this.loading = false
			this.userIdentity = res.data.organizationType;
      if(this.userIdentity === 1){
        this.getNewSelections()
        this.positionId = this.profile.companyPositionId ? this.profile.companyPositionId : ''
        this.departmentId = this.profile.companyDepartmentId ? this.profile.companyDepartmentId : ''
      }
    },
    async saveProfile() {
      // null 判断
      if(this.profile.selfIntroduction?.length > 0 &&this.profile.selfIntroduction?.length < 10){
        ElMessage.warning('自我介绍最少输入10个字符!')
        return
      }
      if (this.imageUrl) {
        this.profile.headImage = this.imgdisplay;
      }
      let notLab = false
      let emailflag = this.labelRule()
      if(!emailflag && this.userIdentity === 0) return
      else if(emailflag && (this.userIdentity === 1 || this.userIdentity === null)){         // 说明是企业或无组织用户 没有邮箱
        this.profile.email = 'enterprise@email.com'
        notLab = true
      }
      if(this.userIdentity === 0 || this.userIdentity === 1){
        let industrys = this.$refs.industrys.getResult()?.map(item=>item[1]) // 多选行业
        if(industrys.length === 0){ //  多选行业未选择，给出提示
          ElMessage.warning('请选择行业!')
          console.log(this.multiIndustry,'hehe');
          return
        }
        this.profile.industryNames = []
        this.profile.industryId = industrys[0]   // 多选行业用初始项赋值
        this.multiIndustry.forEach((item)=>{
          item.child?.find((type)=>{
            if(industrys.includes(type.id)){
              this.profile.industryNames.push(type.val)
            }
          })
        })
        this.profile.industryIds = industrys
        this.profile.industryName = this.profile.industryNames[0]
      }
      else{
        // 无身份用户
        this.profile.industryNames = []
        this.profile.industryId = this.userIndustryIds[0]   // 多选行业用初始项赋值
        this.multiIndustry.forEach((item)=>{
          item.child?.find((type)=>{
            if(this.userIndustryIds.includes(type.id)){
              this.profile.industryNames.push(type.val)
            }
          })
        })
        this.profile.industryIds = this.userIndustryIds
        this.profile.industryName = this.profile.industryNames[0]
      }

      if(this.showNewItems){
        // 判断新增选项
        if(!((this.positionId + '').length > 0)){
          ElMessage.warning('请选择职位!')
          return
        }
        if(!((this.departmentId + '').length > 0)){
          ElMessage.warning('请选择部门!')
          return
        }
      }
      if(this.profile.label.length === 0 && this.userIdentity === 0){          // 实验室研发方向为空
        ElMessage.warning('请填写研发方向!')
        return
      }
      else if(this.profile.label.length > 5 && this.userIdentity === 0){
         ElMessage.warning('研发方向最多不能超过5个!')
        return
      }
      else if(this.profile.label.length > 0 && this.userIdentity === 0){
        let lengthFlag = false
        this.profile.label.find((item)=>{
          if(item.length > 40){
            lengthFlag = true
          }
        })
        if(lengthFlag){
          ElMessage.warning('方向标签每个最长为40字符!')
          return
        }
      }
      if(this.userIdentity === 1){  // 数字科创用户 研究方向
        if(this.userDirection.length === 0){  // 空
          ElMessage.warning('请输入研究方向!')
          return
        }
        if(!this.checkDirection(this.userDirection.replace(/，/ig,','))) return // 研究方向校验
        this.userDirection = this.userDirection.replace(/，/ig,',') // 逗号转换
        this.profile.label = this.userDirection.split(',')
      }
      this.btnDisabled = true
      if(notLab){  // 非产学研用户
        const param =  {
          "id": this.profile.id,
          "handUrl": this.profile.headImage,
          "industryIds": this.profile.industryIds,
          "label": this.profile.label,
          "companyPositionId": this.positionId,
          "companyDepartmentId": this.departmentId,
          "name": this.profile.name,
          "positionId": this.profile.positionId,
          "selfIntroduction": this.profile.selfIntroduction
        }
        editProfileBasic(param).then(() => {
          this.$store.dispatch("user/editUserInfo", this.profile)
          updateProfile() // 更新腾讯个人信息
          ElMessage.success("成功修改个人资料")
          setTimeout(() => {
            this.$router.push('/user-center')
          },500)
        }).finally(() => {
          this.btnDisabled = false
          this.loading = false
        })
        return
      }
      editProfile(this.profile).then(() => {
        this.$store.dispatch("user/editUserInfo", this.profile)
        updateProfile() // 更新腾讯个人信息
        ElMessage.success("成功修改个人资料")
        setTimeout(() => {
          this.$router.push('/user-center')
        },500)
      }).finally(() => {
        this.btnDisabled = false
        this.loading = false
      })
    },
    async getPositionOptions() {
      let res = await getOptionsByType(positionType)
      this.positionOptions = res.data.result
      this.profile.positionId = this.positionOptions[0].id
    },
    async getEducationOptions() {
      let res = await getOptionsByType(educationType)
      this.educationOptions = res.data.result
      this.profile.educationId = this.educationOptions[0].id
    },
    async getIndustryOptions() {
      let res = await getOptionsByType(industryType)
      let l = res.data.result.length
      for(let i = 0;i < l;i++){
        if(!res.data.result[i].pid){  // 去除父类别
          res.data.result.splice(i,1)
          l--
          i--
        }
      }
      this.industryOptions = res.data.result
      res = await getSelectFatherSonByType(6)
      this.multiIndustry = res.data
    },
    async gettechserviceOptions() {
      let res = await getOptionsByType(userServeType)
      this.techserviceOptions = res.data.result
    },
    async loadJsonFromFile(file) {
      this.imageUrl = file
    }
  }
}
</script>

<style scoped lang="scss">
.edit-container {
  .card-top{
    font-size: 30px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
  }
  width: 100%;
  background: rgb(243, 243, 243);
  display: flex;
  justify-content: center;
  padding: 60px 0 60px 0;
  .card-content{
    display: flex;
    justify-content: flex-start;
    .el-form-content{
      padding-top: 50px;
    }
    .form-label{
      font-size: 24px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: 20px;
    }
  }
}

.width360 {
  width: 340px;
}

.avator-center{
	display: flex;
  margin: 20px 0;
	justify-content: center;
	align-items: center;
  width: 218px;
  height: 218px;
  border-radius: 50%;
}
.avatar-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.avatar-fix{
  margin-left: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
