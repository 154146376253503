<template>
  <div class="root">
    <div class="content">
      <h2 class="align-center">科企岛用户使用协议</h2>
      <div class="content-text">
        <div class="Section0" style="layout-grid:15.6000pt;">
          <h4 align=center style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:15.6500pt;
					margin-left:0.0000pt;mso-para-margin-bottom:1.0000gd;text-indent:21.0000pt;
					padding:0pt 0pt 0pt 0pt ;text-autospace:ideograph-numeric;mso-pagination:widow-orphan;
					text-align:center;line-height:12.0000pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:10.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><o:p></o:p></span></h4>
          <h4 style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;line-height:12.0000pt;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">尊敬的用户，欢迎您使用科企岛</font>APP！</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">本协议是您与</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">公司（以下简称为</font>"</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);">"）之间所订立的契约，本协议阐述之条款和条件适用于您依托于</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">的各项服务，请您仔细阅读本</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">使用</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;
					background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">协议。本协议构成对双方有约束力的法律文件。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;font-weight:normal;text-transform:none;
					font-style:normal;font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></h4>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第一条【定义】</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">在本协议及本网站其它相关规定中所使用的下列词语，除另有说明以外，应具有以下含义：</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1.1 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">：指</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1.2 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">：指</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">所有者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛上海网络技术</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">有限公司。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1.3 本公司：指&#8220;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&#8221;。</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1.4 用户：指符合本协议所规定的条件，同意遵守</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">各种规则、条款（包括但不限于本协议），并使用</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务的自然人或法人。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1.5 服务经营者：指符合本协议所规定的条件，同意遵守</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">各种规则、条款（包括但不限于本协议），并通过</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">提供有关服务的经营者。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1.6 您：指用户/服务经营者。</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1.7 服务：指</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">向用户提供的服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1.8 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">账号</font>/登陆名：是本网站向您提供服务时，由您自行注册而获得，用于标示、识别或管理您自身信息，及您发布或获得信息的唯一号码。</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第二条</font> <font face="微软雅黑">服务条款的确认和接纳</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">2.1 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的各项电子服务的所有权和运作权归</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">。您在注册之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可向</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台客服咨询。如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">2.2 您完成注册程序后或以任何方式进入</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">并使用</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">相关</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务即表示您已充分阅读、理解并同意接受本协议的条款和条件</font>(以下合称&#8220;条款&#8221;)，并与</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">达成一致，成为</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;&#8220;用户&#8221;。您同意本协议之效力如同亲自签字、盖章的书面协议一样，对您具有法律约束力。您确认自己具有享受本网站服务的相应民事行为能力，且能够独立承担法律责任。</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第三条</font> <font
              face="微软雅黑">协议范围</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">本协议内容包括协议正文及</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">所有已经发布或将来可能发布的各类规则。所有规则为协议不可分割的一部分，与协议正文具有同等法律效力。如有冲突，则以</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">最新公布的规则为准。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第四条</font> <font
              face="微软雅黑">用户注册</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">4.1 您保证在注册账号时，承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线。您保证在注册和使用帐号时，在账号名称、头像和简介等注册信息中不得出现违法和不良信息，按照《互联网用户账号名称管理规定》，账号名称不得有下列情形：</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=MsoNormal style="mso-pagination:widow-orphan;text-align:left;"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">（一）违反宪法或法律法规规定的；（二）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；（三）损害国家荣誉和利益的，损害公共利益的；（四）煽动民族仇恨、民族歧视，破坏民族团结的；（五）破坏国家宗教政策，宣扬邪教和封建迷信的；（六）散布谣言，扰乱社会秩序，破坏社会稳定的；（七）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；（八）侮辱或者诽谤他人，侵害他人合法权益的；（九）含有法律、行政法规禁止的其他内容的。</font></span><span
              style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
					mso-bidi-font-family:'Times New Roman';font-size:11.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">您保证您在注册和使用账号时，按照相关规定，提供真实有效的信息；如您不提供或提供的信息不真实有效，本公司有权拒绝为您提供相关服务；如您的信息存在违法和不良信息的，本公司有权采取限期改正、暂停使用、注销登记等措施</font> <font
              face="微软雅黑">。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">4.2 您可以使用您的注册用户名、或您提供或确认的邮箱、手机号码或者本公司允许的其它方式作为登录手段进入</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">4.3 您了解并同意，只要您注册成功，您即可以获得您的</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">帐号。您在此明确授权，您的账户信息在您注册成功时，已授权披露给</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">，并同时授权</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">使用，以使您更便捷地使用</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">4.4 在完成注册或激活流程时，您应当按照法律法规要求，按相应页面的提示，准确提供并及时更新您的资料，以使之真实、及时、完整和准确。如有合理理由怀疑您提供的资料错误、不实、过时或不完整的，</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权向您发出询问和</font>/或要求改正的通知，并有权直接做出屏蔽、删除相应资料的处理，直至中止、终止对您提供部分或全部</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第五条</font> <font
              face="微软雅黑">账户安全</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">您须自行负责对您的账户和密码保密，且须对该账户和</font>/或密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意各类规则协议、或提交网上续签协议、购买服务等）承担责任。您同意：</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=MsoNormal style="mso-pagination:widow-orphan;text-align:left;"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">(a) 如发现任何人未经授权使用您的账户和/或密码，或发生违反保密规定的任何其他情况，您会立即通知科企岛网络技术有限公司； (b) 您确保您在每个上网时段结束时，以正确步骤退出。科企岛网络技术有限公司不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。您理解科企岛网络技术有限公司对您的请求采取行动需要合理时间，科企岛网络技术有限公司对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。除非有法律规定或司法裁定，或征得科企岛网络技术有限公司的同意，否则，您的账户、密码不得以任何方式转让、赠与或继承（与账户相关的财产权益除外）。如您征得科企岛网络技术有限公司的同意转让账户，您需自行承担相应成本费用。</span><span
              style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
					mso-bidi-font-family:'Times New Roman';font-size:11.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第六条</font> <font
              face="微软雅黑">用户资料</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&#8220;您的资料&#8221;包括您在注册、交易或</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">使用</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">过程中、在任何公开信息场合或通过任何电子邮件形式，向本公司或其他用户提供的任何资料，包括数据、文本、软件、音乐、声响、照片、图画、影像、词句或其他材料。您应对</font>&#8220;您的资料&#8221;负全部责任，而</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">仅作为您在网上发布和刊登</font>&#8220;您的资料&#8221;的被动渠道。倘若</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">认为您的用户资料可能使本公司及其关联公司承担任何法律或道义上的责任，或可能使</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;(全部或部分地) 失去</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的互联网服务供应商或其他供应商的服务，则</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">可自行全权决定对该资料采取认为必要或适当的任何行动，包括但不限于删除该类资料。您特此保证，您对提交给</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的用户资料拥有全部权利。您确认，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">没有责任去认定或决定您提交的资料哪些是应当受到保护的，对享有服务的其他用户使用您的用户资料，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">也不必负责。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第七条</font> <font
              face="微软雅黑">用户授权</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">7.1 用户同意，</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">拥有通过邮件、短信电话等形式，向在本站注册、使用用户发送商业</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">合作</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">等告知信息的权利。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">7.2 用户同意，</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权使用用户的注册信息、用户名等信息，进行证据保全，包括但不限于公证、见证等。</font> </span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第八条</font> <font
              face="微软雅黑">服务</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">8.1 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">通过</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">依法为用户提供服务，用户在完全同意本协议及符合规定的情况下，方有权使用</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的相关服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">8.2 用户必须自行准备如下设备并承担如下开支：（1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；（2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第九条</font> <font face="微软雅黑">用户的权利和义务</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">9.1 用户的所有行为必须符合国家法律的规定。如违反国家法律规定和本协议，出现任何纠纷及不良结果，将由行为人独立承担所有责任。</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">9.2 用户可随时对</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的服务提出批评、建议。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">9.3 下面所述条款和前面所述的条款均适用于本网站上的商友圈及任何在网站上发生有相互影响作用区域的行为。用户使用、进入或参与上述区域或网站上的任何其他具有相互影响作用区域，则表示同意所有这些条款。用户参与的在线沟通是实时发生的，没有经过任何来自本网站的编辑、压缩、或任何其他形式的控制，本网站不能甄别所有用户提供的内容，但本网站保留对本网站上内容的监控权，并对本网站认为具有危害性，易引起争议性的或违反本条下述操作规则的内容进行删除的权利。为了提供满足用户需求的高价值的服务，并避免本网站用户遭受因对他人权利的不实、恶意攻击或其他有害陈述而引起的损失，本网站有必要建立下列操作规则以避免本网站被滥用：</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">当您使用本网站服务时，您不可以：</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=MsoNormal style="mso-pagination:widow-orphan;text-align:left;"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）粘贴或传播任何非法的，具威胁性的，诽谤性的，贬损的，报复的、亵渎的或任何其他法律禁止的信息，包括但不限于传播任何煽动性鼓励犯罪的，或违反公民义务或任何其他违反国家法律、法规或地方法规或国际法律、惯例或公约的内容。（2）粘贴或传播任何散布他人的私人事件，粘贴或传播带有病毒，或任何带有贬损或损害性特征的内容；（3）粘贴或传播任何可能侵害其他人权利的数据、图形或程序，包括以非法形式使用商标标识、版权文本、图形或程序，商业秘密及其他保密信息等；（4）以任何形式干扰其他用户。</span><span
              style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
					mso-bidi-font-family:'Times New Roman';font-size:11.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">9.4 用户保证提交的信息真实、准确、及时、详尽和完整并与相关情况一致，没有任何可能引人误解的陈述和虚假信息。客户同意接受</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">电话、邮件或上门拜访等方式确认。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第十条</font> </span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">服务经营者</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">规则</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">10.1 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">应遵守</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的有关规定，采取符合</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">规定的程序方式入驻</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台，享受</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台提供的服务；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">10.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">2</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">应遵守法律、法规等政策性文件进行合法</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">交易</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">；全面、真实、准确披露</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">产</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">品或服务信息，保证</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">产</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">品或服务的完整性；不得采取不正当竞争的手段扰乱市场秩序；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">10.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">3</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">应保证对其上传至</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台的一切信息（包括但不限于注册信息、账号信息、</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">产</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">品信息等）拥有合法的权利，因此类信息导致的一切投诉、举报、处罚、诉讼等，都将由</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">承担所有的责任，如因此导致</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">承担任何责任的，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">须赔偿</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">所遭受的全部损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），无论</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">与</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的合作协议、本协议等是否终止。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">10.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">4</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">可基于自己的意愿退出</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台，在自行终止从事电子商务活动前，应向</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">发出书面申请，经</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">审核通过后，注销其账号；账号注销之后，本协议及依托于该账号在</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">与</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">之间达成的所有协议随即终止。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">10.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">5</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">明确同意：在</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">与</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的各种合作协议终止之后至</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">账户注销之前，由于</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">留存在</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台上的一切信息所引起的各种纠纷由</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">自行承担，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务经营者</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">承诺放弃通过各种方式（包括但不限于诉讼、投诉、举报等）向</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">主张任何权利。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第十</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">一</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">条</font> <font
              face="微软雅黑">权利声明</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">1</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.1 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">拥有</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">内容及资源的所有合法权利</font>,受国家法律保护,有权不时地对本协议及本站的内容进行修改，并在本站公布，无须另行单独通知用户。</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.2 除法律另有强制性规定外，未经</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">明确的书面许可</font>,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的信息内容，否则，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权追究其法律责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.3 本网站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">或其提供者的财产，受中国和国际相关法律的保护。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">上所有内容的汇编是</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的排他财产，受中国和国际相关法律的保护。本网站上所有软件都是</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">或其关联公司或其软件供应商的财产，受中国和国际相关法律的保护。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.4 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">所拥有的注册域名均受相关法律保护。该等商标标识及域名等未经</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">书面许可，不得在任何非属</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的产品或服务上进行任何形式使用。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.5 对</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">上的任何内容及可以获取的信息资源的任何形式的使用，包括再造、修改、发布、转发、再版、演示或播出都被严格禁止。</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.6</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">及其任何组成部分不得被再造、复制、抄袭、交易，或为任何未经</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">允许的商业目的所使用。如果</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">确定客户行为违法或有损其网站和企业的利益，则</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">和其关联企业有权采取制止措施并追究相应的责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.7 用户一旦接受本协议，即表明该用户主动将其在任何时间段在</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">上发表的任何形式的信息内容（包括但不限于评价、客户、</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">动态</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">、</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">文章等信息内容）的财产性权利等任何可转让的权利全部独家且不可撤销地转让给</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">所有，用户同意</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权就任何主体侵权而单独提起诉讼。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.8 本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2010年修正版著作权法确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.9 用户同意并已充分了解本协议的条款，承诺不将已发表于本站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">11</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.10 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">尊重他人之知识产权。如您发现</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">站上的信息，侵犯了您的合法权益，您可以向</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">进行投诉；</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">接到您的投诉后，会及时将投诉信息转送到</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">内用户，并采取删除、屏蔽、断开链接、中止交易和服务等必要措施；如</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">内用户提交声明保证不存在侵权行为的，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">将会及时将声明转送到您；如您对声明保证有异议，您可以向有关行政部门投诉或者向人民法院起诉。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第十</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">二</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">条</font> <font face="微软雅黑">责任限制及不承诺担保</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">12</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.1 鉴于</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台提供的服务的性质，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台上的</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">实验室</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">、</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">企业</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">信息（包括但不限于</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">实验室名称、</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">公司名称、</font> <font face="微软雅黑">联系人及联络信息、产品的描述和说明、相关图片等）均由会员自行提供并上传，由会员对其提供并上传的所有信息承担相应法律责任。</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">12</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">2</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;<font face="微软雅黑">除非另有明确的书面说明</font>,</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">及其所包含的或以其它方式通过</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在</font>"按现状"和"按现有"的基础上提供的。</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">12</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">3</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;<font face="微软雅黑">除非另有明确的书面说明</font>,</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">不对</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">的运营及包含的信息、内容、材料、产品（包括软件）或服务作任何形式的明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">12</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">4</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">不担保</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">所包含的或以其它方式提供给您的全部信息、内容、资料、产品（包括软件）和服务、及从服务器或</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">从科企岛网站或软件</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">发出的电子信件、信息没有病毒或其他有害成分。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">12</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">5</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;<font face="微软雅黑">对您使用</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">或相关的任何内容、服务或其它链接的站点、内容等</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">均不作直接、间接、法定、约定的保证。所导致的任何直接的、间接的、相关的、后果性的声誉上或金钱上的损失</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">均不承担任何责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第十</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">三</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">条</font> <font
              face="微软雅黑">违约及处理</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">13</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.1 发生如下情形之一的，视为您违约：</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=MsoNormal style="mso-pagination:widow-orphan;text-align:left;"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">（</font>1）使用科企岛平台服务时违反有关法律法规规定的；（2）违反本协议或纳入本协议的其他文件的；</span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
					mso-bidi-font-family:'Times New Roman';font-size:11.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">13</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.2 为满足海量用户对高效优质服务的需求，您理解并同意，</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">可在</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台规则中约定违约认定的程序和标准。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">13</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.3 您在</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台上发布的信息构成违约的，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">可根据相应规则对相应信息进行删除、屏蔽处理。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">13</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.4 您在</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台上实施的行为，或虽未在</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台上实施但对</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台及其用户产生影响的行为构成违约的，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">可依据相应规则中止向您提供部分或全部服务等处理措施。如您的行为构成根本违约的，</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">可屏蔽、注销您的账户，终止向您提供服务。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">13</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.5 当您违约的同时存在欺诈、售假、盗用他人账户等特定情形或您存在危及他人交易安全或账户安全风险时，</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">会依照您行为的风险程度对您的账户采取屏蔽等强制措施。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">13</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.6 您同意，如因您违反本协议或纳入本协议的其他文件，或因您违反法律侵害了第三方的合法权利，或因您违反法律须承担行政或刑事责任，而使第三方或行政、司法机关对</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">及其子公司、分公司、关联公司、董事、职员、代理人等提出索赔或处罚，您必须全额（包括司法费用和其他专业人士的费用）赔偿给</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">，并使其免遭损失。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第十</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">四</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">条</font> <font
              face="微软雅黑">协议的更新</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">根据国家法律法规变化及网站运营需要，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权对本协议条款不时地进行修改，修改后的协议一旦被公布</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">立</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">即生效，并代替原来的协议。用户可随时登录查阅最新协议，用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">依据本协议提供的服务；如用户继续使用本网站提供的服务的，即视为同意更新后的协议。</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">建议您在使用之前阅读本协议及公告。如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响其余任何条款的有效性和可执行性。</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第十</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">五</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">条</font> <font
              face="微软雅黑">协议的终止</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">15</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.1 终止的情形：</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=MsoNormal style="mso-pagination:widow-orphan;text-align:left;"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）您违反本协议约定，科企岛网络技术有限公司依据违约条款终止本协议的；（2）变更事项生效后您停止使用并明示不愿接受变更事项的；（3）您明示不愿继续使用科企岛平台服务，自愿终止本协议的；</span><span
              style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
					mso-bidi-font-family:'Times New Roman';font-size:11.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">15</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.2 终止后的权利义务：</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=MsoNormal style="mso-pagination:widow-orphan;text-align:left;"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）本协议终止后，除法律有明确规定外，科企岛网络技术有限公司无义务向您或您指定的第三方披露您账户中的任何信息。（2）本协议终止后，科企岛网络技术有限公司仍享有下列权利：1.继续保存您留存于科企岛平台的各类信息；2.对于您过往的违约行为，科企岛网络技术有限公司仍可依据本协议向您追究违约责任。3.本协议终止后，对于您在本协议存续期间产生的交易订单，科企岛网络技术有限公司可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</span><span
              style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
					mso-bidi-font-family:'Times New Roman';font-size:11.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第十</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">六</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">条</font> <font face="微软雅黑">不可抗力及免责事项</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">16</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.1 &#8220;不可抗力&#8221;是指无法预见、无法克服或避免的且对用户或</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">造成重大影响的客观事件。包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等，以及社会事件，如战争、动乱、立法，政府行为等。客户同意：鉴于互联网的特殊性，如黑客攻击、网络中断等，凡出现非</font></span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">过错之情形的，均为不可抗力。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">16</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.2 因不可抗力或者其他意外事件，使得本服务条款履行不能的，</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">不承担任何责任。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第十</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">七</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">条</font> <font face="微软雅黑">法律适用及争议解决</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">17</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.1 本协议的订立、执行、解释及争议解决均应适用在中华人民共和国法律（但不包括其冲突法规则）。 如发生本协议部分条款与适用法律相抵触时，则这些条款被视为无效，而其它条款继续有效。</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">17</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.2 对本条款的理解与解释应依据条款目的和文本原义以及业界通行的理解和惯例进行，标题仅供参考，不应在解释本条款时加以援引。</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">17</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.3 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">上海</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">市有管辖权的人民法院提起诉讼。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <h5 style="margin-top:10.0000pt;margin-right:0.0000pt;margin-bottom:5.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;border-bottom:1.0000pt solid rgb(221,221,221);
					mso-border-bottom-alt:0.5000pt solid rgb(221,221,221);padding:0pt 0pt 5pt 0pt ;mso-pagination:widow-orphan;
					background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">第十</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">八</font></span></b><b><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;
					background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="微软雅黑">条</font> <font
              face="微软雅黑">其他事项</font></span></b><b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
					letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
					font-style:normal;font-size:9.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h5>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">18</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.1 </span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">尊重用户的合法权利，本协议及网站</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">和软件</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font
              face="微软雅黑">上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户提供服务。本网站欢迎用户和社会各界提出意见和建议，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛网络技术有限公司</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">将虚心接受并适时修改本协议及本网站上的各类规则。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">18</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">2</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;<font face="微软雅黑">您点击</font>&#8220;免费注册&#8221;按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。</span><span
              style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">18</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">3</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台规则中如对此协议所涉及事项另有详细规定的，依照其规定。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=p style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">18</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">.</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">4</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">&nbsp;<font face="微软雅黑">本协议自公布之日起生效。</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p>
          <p class=MsoNormal align=right style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
					margin-left:0.0000pt;text-indent:0.0000pt;padding:0pt 0pt 0pt 0pt ;
					mso-pagination:widow-orphan;text-align:right;mso-line-height-alt:12pt;
					background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><font face="微软雅黑">科企岛</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);"><br></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:7.5000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
					mso-shading:rgb(255,255,255);">2021年01月09日</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(51,51,51);
					letter-spacing:0.0000pt;text-transform:none;font-style:normal;
					font-size:6.5000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p>
          <p class=MsoNormal><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
					mso-bidi-font-family:'Times New Roman';font-size:11.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "userstatement",
  created() {
    this.$store.commit('message/SET_ACTIVENAV', '-')
    this.$store.commit('message/SET_BG', false)
  }
}
</script>

<style scoped>
.root {
  -moz-user-select: none;
  /* Firefox */
  -webkit-user-select: none;
  /* WebKit */
  -ms-user-select: none;
  /* IE */
  -khtml-user-select: none;
  /* KHTML */
  -o-user-select: none;
  /* Opera */
  user-select: none;
  /* CSS3属性 */

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 50px;
}

.content {
  width: 1000px;
  line-height: 2;
}

.align-center {
  text-align: center;
}

.content-text {
  font-size: 18px;
  tab-interval: 21pt;
  text-justify-trim: punctuation;
}

.bold {
  font-weight: bold;
}

.suojin {
  text-indent: 2em;
}

@font-face {
  font-family: "Times New Roman";
}

@font-face {
  font-family: "宋体";
}

@font-face {
  font-family: "Wingdings";
}

@font-face {
  font-family: "Calibri";
}

@font-face {
  font-family: "微软雅黑";
}

p.MsoNormal {
  mso-style-name: 正文;
  mso-style-parent: "";
  margin: 0pt;
  margin-bottom: .0001pt;
  mso-pagination: none;
  text-align: justify;
  text-justify: inter-ideograph;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 10.5000pt;
  mso-font-kerning: 1.0000pt;
}

h4 {
  mso-style-name: "标题 4";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 5.0000pt;
  margin-bottom: 5.0000pt;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: 宋体;
  font-weight: bold;
  font-size: 12.0000pt;
}

h5 {
  mso-style-name: "标题 5";
  mso-style-noshow: yes;
  mso-style-next: 正文;
  margin-top: 5.0000pt;
  margin-bottom: 5.0000pt;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: 宋体;
  font-weight: bold;
  font-size: 10.0000pt;
}

span

.10
{
  font-family: 'Times New Roman'
;
}

p.p {
  mso-style-name: "普通\(网站\)";
  margin-top: 5.0000pt;
  margin-right: 0.0000pt;
  margin-bottom: 5.0000pt;
  margin-left: 0.0000pt;
  mso-margin-top-alt: auto;
  mso-margin-bottom-alt: auto;
  mso-pagination: none;
  text-align: left;
  font-family: Calibri;
  mso-fareast-font-family: 宋体;
  mso-bidi-font-family: 'Times New Roman';
  font-size: 12.0000pt;
}

span.msoIns {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: underline;
  text-underline: single;
  color: blue;
}

span.msoDel {
  mso-style-type: export-only;
  mso-style-name: "";
  text-decoration: line-through;
  color: red;
}

table.MsoNormalTable {
  mso-style-name: 普通表格;
  mso-style-parent: "";
  mso-style-noshow: yes;
  mso-tstyle-rowband-size: 0;
  mso-tstyle-colband-size: 0;
  mso-padding-alt: 0.0000pt 5.4000pt 0.0000pt 5.4000pt;
  mso-para-margin: 0pt;
  mso-para-margin-bottom: .0001pt;
  mso-pagination: widow-orphan;
  font-family: 'Times New Roman';
  font-size: 10.0000pt;
  mso-ansi-language: #0400;
  mso-fareast-language: #0400;
  mso-bidi-language: #0400;
}

@page {
  mso-page-border-surround-header: no;
  mso-page-border-surround-footer: no;
}

@page Section0 {
  margin-top: 72.0000pt;
  margin-bottom: 72.0000pt;
  margin-left: 90.0000pt;
  margin-right: 90.0000pt;
  size: 595.3000pt 841.9000pt;
  layout-grid: 15.6000pt;
}

div.Section0 {
  page: Section0;
}
</style>
