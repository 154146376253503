<template>
  <div class="root">
    <div class="content">
      <h2 class="align-center">科企岛VIP会员服务协议</h2>
      <div class="update-time">
        <span>本版本更新时间：2021年01月20日</span>
      </div>

      <div class="content-text">
        <p>欢迎您使用科企岛VIP会员服务！</p>

        <p>科企岛VIP会员服务由科企岛（上海）网络技术有限公司（以下亦称“科企岛”）向您提供，注册地址为：上海市松江区广富林街道龙腾路中星创意园2号楼803。</p>

        <p class="bold">
          《科企岛VIP会员服务协议》（以下亦称“本协议”）由您（以下亦称“会员”或“VIP会员”）和科企岛进行缔结，对双方具有同等法律效力。科企岛建议您仔细阅读本协议的全部内容，尤其是以加粗形式展示的，与您的权益（可能）存在重大关系的条款（包括相关约定科企岛责任、您享有的权利、争议解决方式及司法管辖等条款），请您留意重点阅读。若您认为本协议中的加粗条款可能会导致您的部分或全部权利或利益受损，请您务必再次仔细阅读，在确保您已经理解、接受了加粗条款的前提下，继续使用科企岛VIP会员服务。</p>

        <p class="bold">
          如果您不同意本协议的任一或全部条款内容，请不要以确认形式（包括但不限于支付行为/接受赠与、或完成了成为会员的全部程序而在此过程中未向科企岛提出关于本协议的任何异议、或使用VIP会员服务）进行下一步操作或使用科企岛VIP会员服务。当您以确认形式进行下一步操作或使用科企岛VIP会员服务时，即表示您与科企岛已达成协议关系，您自愿接受本协议并遵守本协议项下的全部约定。</p>

        <p class="bold">
          科企岛有权变更本协议内容，一旦本协议内容发生变更的，科企岛将在相应页面、站内信或以其他合理方式进行通知，请您仔细阅读。如果您不同意变更的内容的，您可以选择停止使用VIP会员服务。如您继续使用VIP会员服务的，则视为您已经同意变更的全部内容。更新后的协议自文首更新之日起生效。</p>


        <h4>一、服务说明</h4>
        <p>1. <span class="bold">【科企岛VIP会员】</span>是指完成了成为会员的所有程序，且在遵守本协议的前提下，在会员时长有效期内享受科企岛提供的VIP会员服务的自然人。</p>
        <p>科企岛VIP会员包括月度VIP会员、季度VIP会员及年度VIP会员等类型，具体规则以科企岛在相关页面的说明为准。</p>
        <p>2. <span class="bold">【科企岛VIP会员服务】</span>是由科企岛向您提供的专享的会员权益，具体权益内容说明请您查看本协议第四节。</p>
        <p>3. <span class="bold">【科企岛VIP会员服务协议】</span>是《科企岛服务协议》、《科企岛隐私政策》的补充协议，是其不可分割的一部分，与其具有同等法律效力；本协议如与前述协议发生冲突的，以本协议为准。
        </p>
        <p>4. <span class="bold">【科企岛VIP会员服务规则】</span>是科企岛在科企岛VIP会员中心已经或未来不时发布的与VIP会员服务相关的协议、公告、页面说明、通知、FAQ等内容。前述内容一经发布即生效，均构成本协议不可分割的一部分。
        </p>

        <h4>二、使用服务</h4>
        <p>1. 服务获取</p>
        <ul>1.1 科企岛VIP会员服务为收费服务，您可以通过支付相应的服务费用购买。</ul>
        <ul>1.2 您在获取科企岛VIP会员服务时，应当遵守法律法规、本协议约定，不侵犯第三方或科企岛的合法权益。您不得自行（或协助他人）通过以下方式获取VIP会员服务：
          <ul>（1） 以商业性或其他非个人使用等目的；</ul>
          <ul>（2） 通过机器人软件、蜘蛛软件、爬虫软件等任何自动程序、脚本、软件等方式；</ul>
          <ul>（3） 未经科企岛允许通过转移、赠与、借用、租用、销售、转让VIP会员服务的方式；</ul>
          <ul>（4） 通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、滥用会员身份、黑色产业、投机等）；</ul>
          <ul>（5） 通过利用或破坏科企岛VIP会员服务规则的方式。</ul>
          <ul>科企岛在此声明：任何未经科企岛明示授权而销售、转让科企岛VIP会员资格的行为属于非法销售、非法转让，科企岛有权追究其法律责任。</ul>
        </ul>
        <p>2. 服务使用的基本原则</p>
        <ul>2.1
          您在使用科企岛VIP会员服务的过程中，应遵守法律法规及其他规范性文件，包括但不限于《中华人民共和国保守国家秘密法》、《中华人民共和国著作权法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《中华人民共和国网络安全法》、《信息网络传播权保护条例》等；应遵守公共秩序，尊重社会公德，不得危害网络安全，不得利用网络从事危害国家安全、荣誉和利益，煽动颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视，传播暴力、淫秽色情信息，编造、传播虚假信息扰乱经济秩序和社会秩序，以及侵害他人名誉、隐私、知识产权和其他合法权益等活动。在任何情况下，科企岛一旦合理地认为您存在上述行为的，可以在任何时候，不经事先通知终止向您提供科企岛VIP会员服务。
        </ul>
        <ul>2.2 科企岛授予您对科企岛VIP会员服务一项个人的、非独家的、不可转让的、非商业用途的、可撤销的、有期限的使用许可。<span
            class="bold">即：您仅可出于个人、非商业的目的使用VIP会员服务。</span></ul>
        <p>3. <span class="bold">服务内容的变更</span></p>
        <ul><span class="bold">科企岛有权根据法律法规及政策变更、版权方要求、自身运营策略变更对VIP会员服务内容（包括但不限于VIP会员权益细则、收费标准、收费方式）进行部分或全部变更。就前述变更，科企岛将通过相应服务页面、站内信通知或以其他合理方式进行发布，并于发布之日起生效。发布后，您继续操作的行为（包括但不限于点击同意、或继续购买、或完成支付行为、或使用VIP会员服务等），均视为您已经接受前述变更。</span>
        </ul>
        <p>4. 第三方服务内容</p>
        <ul><span class="bold">若您在科企岛平台的搜索结果中展示的链接等为非科企岛的，在您点击后，将跳转至第三方页面为您提供服务。该类服务由第三方提供与负责，而非科企岛提供的VIP会员服务。科企岛在此善意提醒您，第三方会依据其平台规则（包括会员规则，如涉及）向您提供服务并收取服务费（如有）。</span>
        </ul>

        <h4>三、您的账号</h4>
        <p>1. 账号获得</p>
        <ul>在您的VIP会员服务的有效期限内，您享有VIP会员权益的科企岛账号即为您的科企岛VIP会员账号（即：与您的VIP会员服务绑定的科企岛账号，以下亦称“VIP账号”或“会员账号”）。</ul>
        <ul>同时，科企岛在此善意提醒您，您应当在遵守科企岛账号使用规则的前提下，使用您的会员账号。科企岛建议您查阅《科企岛用户使用协议》及相关协议、各类公告、页面说明和规范流程、FAQ等以获得更多关于账号使用规则的内容。</ul>
        <p>2. 登录</p>
        <ul>科企岛VIP会员服务需要您登录您的科企岛VIP会员账号方可使用。</ul>
        <p>3. 账号管理及安全</p>
        <ul>3.1 您应自行负责并妥善、正确地保管、使用、维护您的VIP会员账号和密码，并对您的账号和密码采取必要和有效的保密措施。<span class="bold">非科企岛法定过错导致的任何遗失、泄露、被篡改、被盗以及其他因保管、使用、维护不当而造成的损失，您应自行承担。</span>
        </ul>
        <ul>3.2 如果您发现有人未经授权使用了您的账号或您的账号存在其他异常情况导致无法正常登录使用的，则您需要按照科企岛官方公布的账号找回流程进行账号找回。在找回过程中，科企岛可能会要求您提供相应信息及/或证明资料，<span
            class="bold">请确保您所提供的内容真实有效，否则将可能无法通过科企岛的验证而导致找回失败。</span></ul>
        <ul>3.3 为保护账号安全，防止账号被盗等情况发生，科企岛可能会不时或定期采用一种或多种方式对账号使用者进行用户身份验证（如短信验证、邮件认证等）、<span class="bold">如未成功通过验证的，科企岛有合理理由怀疑该账号出现被盗等不安全情况，并视情节严重情况而决定是否中止向该账号继续提供会员服务及/或采取进一步措施。</span>
        </ul>
        <ul>3.4 请您特别注意，<span class="bold">您的VIP会员账号下的行为视为您本人的行为，您应对您的VIP账号下发生的活动或通过该账号进行的活动负责。</span></ul>
        <p>4. 信息查询</p>
        <ul>您可以通过登录科企岛VIP会员中心免费查询您的账号信息详情，包括已开通的VIP会员服务内容、服务期限、消费记录等。</ul>
        <p><span class="bold">5. 登录限制</span></p>
        <ul>5.1 <span class="bold">科企岛VIP会员账号可在手机端、Pad端、电脑端以及上述终端相应的网页端使用，具体适用的设备终端以科企岛实际提供为准。</span>
          <ul><span class="bold">原则上：</span></ul>
          <ul>（1） <span class="bold">同一VIP账号最多可在四个设备终端登录（手机端App一个、Pad端App一个、电脑端App一个、网页端一个）；</span></ul>
          <ul>（2） <span class="bold">同一VIP账号一天最多可在三个设备终端上使用；</span></ul>
          <ul>（3）<span class="bold"> 同一VIP账号同一时间最多可在两个设备终端上使用。</span></ul>
          <ul><span class="bold">如您超出上述使用限制而造成您的损失，将由您自行承担，且科企岛有权视您的超出使用情况对您作出限制登录、限制使用、中断或终止服务等处理措施。</span></ul>
        </ul>
        <ul>5.2 为了您顺利使用科企岛VIP会员服务，科企岛在此建议您，若您超过上述限制，应当立刻采取（1）关闭其他设备终端；（2）及时修改账号的密码并重新进行登录。您可以在产品中查阅、管理您的VIP账号的设备终端登录情况。
        </ul>
        <p>6. 账号独立</p>
        <ul><span
            class="bold">您的VIP账号仅限您本人出于非商业目的进行使用。除非科企岛同意，会员权益不可在不同账号之间进行转移、迁徙、转让、赠与、售卖、租借、分享，即使前述不同账号由同一人拥有使用权。</span>科企岛在此善意提醒您，您在进行会员服务购买/参与活动时请注意区分，以免造成不必要的损失。
        </ul>
        <p>7. <span class="bold">权利归属</span></p>
        <ul><span class="bold">科企岛VIP会员账号、VIP会员账号下的VIP会员服务及其涉及的产品及/或服务的所有权及相关知识产权归科企岛所有或经过授权使用，您仅拥有前述产品及/或服务的有限使用权。但您经合法渠道取得的实体产品所有权、账号下归属于您的个人财产、科企岛另有说明的其他权益除外。</span>
        </ul>

        <h4>四、会员权益</h4>
        <p>1. <span class="bold">VIP会员权益</span></p>
        <ul>
          科企岛VIP会员权益，包括精确搜索、一键发布需求、实时互动、一对一沟通和在线生成合同等，具体以科企岛平台上VIP特权页面的说明或科企岛实际提供为准，本协议项下的会员权益名称与页面的说明不一致的，不影响您所实际享受的会员权益的内容。
        </ul>
        <p>2. 设备及系统差异</p>
        <ul>
          您成为VIP会员后，可能会因您使用的软件版本、设备、操作系统等不同以及其他第三方原因导致实际可使用的具体权益或服务内容有差别，由此可能给您带来的不便，您表示理解且不予追究或豁免科企岛的相关责任。科企岛建议您可以通过升级应用程序或操作系统版本、更换使用设备等方式来尝试解决，或者直接通过本协议提供的方式与科企岛联系进行解决。
        </ul>
        <p>3. 使用规范</p>
        <ul>您在获取、使用科企岛VIP会员服务(包括参与活动)时，应遵循科企岛官方渠道公布的流程、平台规范等：</ul>
        <ul>3.1
          不得通过未经科企岛授权或公布的途径、方式、渠道非法获取科企岛VIP会员服务（包括但不限于部分或全部会员权益、VIP会员账号）及/或参与活动获取福利（包括但不限于会员权益、虚拟产品、实体商品、会员服务兑换码，本条下同）；
        </ul>
        <ul>3.2 不得通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、滥用会员身份、黑色产业、投机等违背科企岛提供服务/举办活动的初衷的方式）参与活动或者获取福利。</ul>
        <ul><span class="bold">若您违反上述约定，则科企岛有权对您所获得的福利作出删除、取消、清零等处理，且有权终止向您提供服务，由此造成的全部损失由您自行承担。</span></ul>
        <p>4. 服务期限</p>
        <ul>4.1
          您的VIP会员服务的服务期限以您自行选择并支付相应会员费用对应的服务期限为准，自您成为VIP会员之时起算，您可以通过登录科企岛VIP会员中心免费查询。该期限不因您未使用平台服务或交易等情况而延长。当会员服务期限到期后，科企岛将停止继续向您提供VIP会员服务；但如您开通自动续费服务且您在服务期限到期前续费成功的，会员服务期限将在原服务期限的基础上顺延。<span
              class="bold">自动续费的VIP会员服务在当前周期内的服务有效期到期前，您可以单方面停止自动续费，科企岛亦可以根据运营策略需要而单方面停止继续向您自动续费服务，VIP会员服务期限自当前服务周期届满之日起终止。</span>
        </ul>
        <ul>4.2 <span class="bold">请您理解，VIP会员服务期限中包含科企岛解决故障、服务器维修、调整、升级等或因第三方侵权处理所需用的合理时间，对上述情况所需用的时间或造成的任何损失（如有），科企岛不予任何形式的赔偿/补偿，但科企岛会尽可能将影响降至最低。</span>
        </ul>

        <h4>五、收费及退订</h4>
        <p>1. 收费方式</p>
        <ul>
          科企岛VIP会员服务为收费服务，您可通过科企岛实际支持的付费方式完成VIP会员费用的支付，如银行卡支付、第三方支付等。请您注意，若您使用您的苹果账户或与您的会员账号绑定的通信账户进行支付，此付费方式为代收费运营商托收的付款方式，您通过此种付费方式付费可能存在一定的商业风险（如不法分子利用您账户或银行卡等有价卡等进行违法活动），该等风险可能会给您造成相应的经济损失，您应自行承担全部损失。
        </ul>
        <p>2. 费用退还</p>
        <ul>VIP会员服务系网络商品和虚拟商品，采用先收费后服务的方式，会员费用是您所购买的会员服务所对应的网络商品价格，而非预付款或者存款、定金、储蓄卡等性质，<span class="bold">VIP会员服务一经开通后不可转让或退款（如因VIP会员服务存在重大瑕疵导致您完全无法使用等科企岛违约情形、本协议另有约定、法律法规要求必须退款的或经科企岛判断后认为可以退款等除外）。</span>
        </ul>
        <ul>科企岛在此特别提醒您，您在购买VIP会员服务（包括自动续费服务）之前应仔细核对账号信息、购买的服务内容、价格、服务期限等信息。</ul>
        <p>3. <span class="bold">收费标准、方式的变更</span></p>
        <ul><span class="bold">VIP会员服务（包括自动续费）的收费方式、收费标准由科企岛根据公司的运营成本、运营策略等综合考虑后独立决定（调整包括但不限于促销、涨价等），并在相关的产品服务宣传及支付页面向您展示；若您在购买和续费时，相关收费方式发生变化的，以科企岛实际支持的收费方式为准；相关服务的价格发生了调整的，应以科企岛平台上公示的现时有效的价格为准（但科企岛与您另有约定的情形除外）。您同意您继续操作的行为（包括但不限于点击同意、或继续购买、或完成支付行为、或使用VIP会员服务、或进行额外付费等），即视为您知悉并同意变更后的收费方式、收费标准。</span>
        </ul>
        <p>4. 自动续费</p>
        <ul>
          自动续费服务是指在会员已购买VIP会员服务的前提下，出于会员对于自动续费的需求，避免会员因疏忽或其他原因导致未能及时续费而推出的服务。如会员选择开通自动续费服务的，即会员授权科企岛可在会员服务期限即将过期时或会员服务的订阅周期即将到期时，委托支付渠道从与您的自有充值账户、绑定的第三方支付账户、银行卡、通信账户等（以下统称“账户”）余额中代扣下一个计费周期的费用。该服务实现的前提是您已开通自动续费及绑定相关账户，且可成功从其上述账户中足额扣款。
          <ul>4.1 计费周期：如月度、季度、年度等（具体以科企岛提供的为准），会员可自行选择。</ul>
          <ul>4.2 自动扣款规则：
            <ul>
              （1）会员选择开通自动续费的，则视为同意授权科企岛可在会员服务期限即将过期时，依据支付渠道的扣款规则发出扣款指令，并同意支付渠道可以根据科企岛发出的扣款指令，在不验证会员账户密码、支付密码、短信校验码等信息的情况下从账户中扣划下一个计费周期的费用。苹果IOS渠道的自动扣费规则以苹果公司的规定为准。
            </ul>
            <ul>（2）除非会员或科企岛主动明确地取消了自动续费，否则，自动扣款长期有效、不受次数限制。</ul>
            <ul>（3）<span class="bold">如果您未主动明确地取消自动续费，则将视为您同意科企岛可依据支付渠道的扣款规则在会员服务期限到期后的一定期限内进行不时的扣款尝试（即使您账户内金额不足）。</span>
            </ul>
            <ul>（4）一旦扣款成功，科企岛将为您开通本次计费周期对应的VIP会员服务。</ul>
          </ul>
        </ul>
        <p>4.3 取消方式：</p>
        <ul>（1）苹果移动设备端：苹果移动设备“APP Store” --> 点击右上角苹果账户头像，进入“账户”--> “订阅”，选择“科企岛”取消订阅；</ul>
        <ul>（2）安卓移动设备端：科企岛APP --> “我的”--> “帮助反馈” -->“自动续费管理”，选择“取消自动续费”；</ul>
        <ul>（3）电脑网页端：点击“头像” --> “帮助中心” --> “开通/取消自动续费”，选择“取消自动续费”。
          <ul>您知悉并同意，自动续费状态更新可能存在显示延时，具体以科企岛APP内显示的您的续费状态为准。<span class="bold">且您在成功取消前，已经委托科企岛自动续费的扣款指令仍然有效，科企岛对于基于该指令在取消操作完成前已经扣除的费用不予退还和补偿。</span>
          </ul>
        </ul>

        <h4>六、您的行为规范和违约处理</h4>
        <p>1. <span class="bold">在您使用科企岛VIP会员服务的过程中，不得存在以下行为：</span></p>
        <ul>1.1 未经科企岛明确授权，通过技术手段对服务内容、服务期限、消费金额、交易状态等信息进行修改；</ul>
        <ul>1.2 将VIP会员服务通过非科企岛明确授权的方式（包括但不限于转移、赠与、借用、租用、销售、转让）提供他人使用；</ul>
        <ul>1.3 未经科企岛明确授权，将VIP会员服务提供的内容的全部或部分进行复制、下载、上传、修改、编目排序、翻译、发行、开发、转让、销售、展示、传播、合成、嵌套、链接、创作衍生作品、进行商业开发或推广等；</ul>
        <ul>1.4 对科企岛用于保护VIP会员服务的任何安全措施技术进行破解、更改、反操作、篡改或其他破坏；</ul>
        <ul>1.5 未经科企岛事先书面同意，删除VIP会员服务内容上的任何所有权或知识产权声明或标签；</ul>
        <ul>1.6 未经科企岛明确授权，采用收费或免费的方式，在任何公开场合全部或部分展示VIP会员服务内容（但如您的上述行为不构成侵权的除外）；</ul>
        <ul>1.7 其他未经科企岛明示授权许可或违反本协议、法律法规或监管政策、侵犯第三方或科企岛合法权益的行为。</ul>
        <p>2. <span class="bold">您知悉并同意，如您存在或科企岛经独立判断后认为您存在任何违反国家法律法规或监管政策、违反本协议或有损科企岛或/及其关联公司的声誉、利益的行为的，科企岛有权独立决定采取以下一项或多项处理措施：</span>
        </p>
        <ul>2.1 <span class="bold">如本协议对此行为有单独条款约定处理方式的，按照该条款处理；</span></ul>
        <ul>2.2 <span class="bold">无需通知您而采取一种或多种措施制止您的行为及行为产生的后果，如删除/屏蔽相关链接或内容、限制/取消您的账号/账户使用权限等；</span></ul>
        <ul>2.3 <span class="bold">无需通知您而中断或终止部分或全部VIP会员服务，且您已交纳的VIP会员服务费用将不予退还且不获得任何形式的补偿/赔偿；</span></ul>
        <ul>2.4 <span class="bold">如您的行为使科企岛或/及其关联公司遭受任何损失的，您应当承担全部损失赔偿责任并在科企岛要求的时限内完成费用支付。</span></ul>

        <h4>七、服务的中断和终止 </h4>
        <p>1. 本服务的中断或终止包括如下情况：</p>
        <ul>1.1 您主动提出要求的；</ul>
        <ul>1.2 您存在或科企岛经独立判断后认为您存在任何违反国家法律法规或监管政策、违反本协议或有损科企岛或/及其关联公司的声誉、利益的行为的；</ul>
        <ul>1.3 科企岛根据法律法规、监管政策的规定或有权机关的要求；</ul>
        <ul>1.4 科企岛为维护账号与系统安全等紧急情况之需要；</ul>
        <ul>1.5 不可抗力（鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制而造成之暂时关闭、病毒侵袭等影响互联网正常运行之情形）；</ul>
        <ul>1.6 其他科企岛无法抗拒的情况。</ul>
        <p>2. <span class="bold">当发生前述终止的情况时，您与科企岛均认可如下处理方式：</span></p>
        <ul>2.1 <span class="bold">已经产生但未使用的VIP会员权益自动清除且不折现；</span></ul>
        <ul>2.2 <span class="bold">如您在科企岛平台内有正在进行中的交易，科企岛届时将视情况进行合理处理；</span></ul>
        <ul>2.3 <span class="bold">除法律法规另有规定或科企岛另有说明外，科企岛已收取的会员费用不予退还；</span></ul>
        <ul>2.4 <span class="bold">如因您违反本协议导致终止的，科企岛有权视情况要求您承担相应的违约责任；</span></ul>
        <ul>2.5 <span class="bold">除法律法规另有规定或科企岛另有说明外，科企岛无需向您和第三人承担任何责任。</span></ul>

        <h4>八、您的个人信息保护</h4>
        <p>1. 科企岛深知个人信息对您的重要性，因此科企岛非常重视保护您的个人信息，亦将您的个人信息以高度审慎的义务对待和处理。在您使用科企岛VIP会员服务的过程中，科企岛将采用相关技术措施及其他安全措施来保护您的个人信息。</p>
        <p>2. 更多关于个人信息处理和保护规则、用户对个人信息的控制权等内容，请您至科企岛平台上查阅《科企岛隐私政策》的全文（例如：手机科企岛APP的查询路径为：我的-设置-隐私，点击“隐私政策”）。</p>

        <h4>九、通知 </h4>
        <ul>
          为便于您获知与本协议和VIP会员服务相关的信息，您同意科企岛有权通过网页公示、页面提示、弹窗、消息通知、公众号通知、站内信、您预留的联系方式（如手机短信、电子邮件等）等一种或多种方式进行通知，该通知自科企岛发送之时视为已成功送达您。如多种通知方式并存的，则送达时间以上述方式中最早发送之时为准。
        </ul>
        <ul>此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注相应通知。</ul>

        <h4>十、联系科企岛</h4>
        <ul>如您对本协议或使用VIP会员服务的过程中有任何问题（包括问题咨询、投诉等），科企岛专门为您提供了多种反馈通道，希望为您提供满意的解决方案：</ul>
        <ul>
          <li>在线客服/其他在线意见反馈通道：您可与科企岛平台上产品功能页面的在线客服联系或者在线提交意见反馈；</li>
          <li>人工客服通道：您可以拨打科企岛的任何一部客服电话与科企岛联系；</li>
          <li>邮件通道：您可以通过anzhi@keqidao.com邮箱与科企岛联系；</li>
          <li>其他方式：科企岛提供的其他反馈通道。</li>
        </ul>
        <ul>我们会在收到您的反馈后尽快向您答复。</ul>

        <h4>十一、 其他</h4>
        <p>1. 本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。</p>
        <p>2. <span class="bold">如就本协议的签订、履行等发生任何争议的，双方应尽量友好协商解决；协商不成时，任何一方均可向被告住所地享有管辖权的人民法院提起诉讼。</span></p>
        <p>3. 如本协议因与中华人民共和国现行法律相抵触而导致部分无效的，不影响协议的其他部分的效力。</p>
        <p>4. 本协议的标题仅为方便及阅读而设，并不影响正文中任何条款的含义或解释。</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "vipagreement",
  created() {
    this.$store.commit('message/SET_ACTIVENAV', '-')
    this.$store.commit('message/SET_BG', false)
  }
}
</script>

<style scoped>
.root {
  -moz-user-select: none;
  /* Firefox */
  -webkit-user-select: none;
  /* WebKit */
  -ms-user-select: none;
  /* IE */
  -khtml-user-select: none;
  /* KHTML */
  -o-user-select: none;
  /* Opera */
  user-select: none;
  /* CSS3属性 */

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 50px;
}

.content {
  width: 1000px;
  line-height: 2;
}

.update-time {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.update-time span {
  font-size: 12px;

}

.align-center {
  text-align: center;
}

.content-text {
  font-size: 10.5000pt;
}

.bold {
  font-weight: bold;
}

.suojin {
  text-indent: 2em;
}

.suojin4 {
  text-indent: 4em;
}

.suojin6 {
  text-indent: 6em;
}
</style>
